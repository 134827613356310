
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { BaseCommunityClient, CategoryAttachmentClient, ForumTagClient } from '@/services/Services';
import EditTagModal from './editTagModal.vue';
import * as VM from '@/viewModel';
import NewForumPostModal from '../../forum/modals/newForumPostModal.vue';
import AddAllegatiCategoryModal from './addAllegatiCategoryModal.vue';
import { LocalizationServices } from '@/services/LocalizationServices';
import ConfirmModal from '@/components/confirmModal.vue';

@Options({})
export default class AllegatiCategoriesModal extends Vue {

    @Prop({
        default: ""
    }) communityIdentifier: string;

    @Prop() closeCallBack : () => void;

    categoriesAllegati: VM.CategoryAttachmentVM[] = [];

    
    created() {
        this.init();
    }

    init() {
        this.categoriesAllegati = [];

        CategoryAttachmentClient.getCategoryAllAttachmentByCommunity(this.communityIdentifier)
        .then(x => {
            this.categoriesAllegati = x;
        })
        
    }


      


    deleteCategory(categoryId: string) {
        var text =  LocalizationServices.getLocalizedValue("app_vuoieliminarequestacategoria", "Vuoi eliminare questa categoria?");
        var text2 =  LocalizationServices.getLocalizedValue("app_tuttigliallegatiaquestacategori", "Tutti gli allegati con questa categoria non avranno più nessuna categoria!");
        var textValue = text + " " + text2;

        this.$opModal.show(ConfirmModal, {
            text: textValue,
            okCb: () => {
                CategoryAttachmentClient.deleteCategoryAttachment(categoryId)
                .then(x => {
                    this.$opModal.closeLast();
                    this.init();
                })
            }
        })

        
    }

    addCategory(){
        this.$opModal.show(AddAllegatiCategoryModal, {
            communityIdentifier: this.communityIdentifier,
            callback: () => {
                this.$opModal.closeLast();
                this.init();
            }
        });
    }

}

