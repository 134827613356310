
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import FileInput from '@/components/fileInput.vue';
import store from '@/store';
import * as VM from '@/viewModel';
import { BaseCommunityClient } from '@/services/Services';

@Options({
    components: {
        FileInput
    }
})
export default class LogoModal extends Vue {

    @Prop() communityIdentifier: string;

    vm: VM.FileIdentifier = new VM.FileIdentifier();
    isCordova: boolean = !!window.cordova;

    created(){
        this.vm.identifier = this.communityIdentifier;
        this.vm.file = <any>store.state.community.logo;
        this.makePreview();
    }

    save(){
        BaseCommunityClient.updateLogoImage(this.vm.identifier, this.vm.file)
        .then(x => {
            store.state.community.logo = x;
            this.$emit('close');
        })
    }
    
    preview: any = null;
    @Watch('vm.file')
    onLogoChange(next, prev){
        this.makePreview();
    }
    makePreview(){
        if(this.vm.file){
            if(typeof this.vm.file == "string"){
                this.preview = this.vm.file;
            } else {
                var reader = new FileReader();
                reader.onload = (e: any) => {
                    this.preview = e.target.result;
                }
                reader.readAsDataURL(this.vm.file);
            }
        }
    }

}

