
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { EventClient } from '@/services/Services';
import { getUniversalDate, getUniversalISOStringDate } from '@/utils';
import SelectCityModal from '@/components/selectCityModal.vue';
import moment from 'moment';
import store from '@/store';
import * as OM from '@/model';
import { LocalizationServices } from '@/services/LocalizationServices';

@Options({})
export default class EditEventModal extends Vue {

    @Prop() eventIdentifier: string;

    editEventInfo: OM.EditEventInfo = new OM.EditEventInfo();
    timeFrom: string = "";
    timeTo: string = "";

    created(){
        EventClient.getEventAddress(this.eventIdentifier)
        .then( x => {
            this.editEventInfo.communityIdentifier = this.eventIdentifier;
            this.editEventInfo.dateFrom = store.state.community.dateFrom;
            this.timeFrom = moment(getUniversalDate(this.editEventInfo.dateFrom)).format("HH:mm");
            this.editEventInfo.dateTo = store.state.community.dateTo;
            this.timeTo = moment(getUniversalDate(this.editEventInfo.dateTo)).format("HH:mm");
            this.editEventInfo.address = x;
            this.editEventInfo.openFrom = (<any>store.state.community).openFrom;
            this.editEventInfo.openTo = (<any>store.state.community).openTo;
            this.editEventInfo.isOnlineEvent = (<any>store.state.community).isOnlineEvent;
        })
    }

    save(){
        var dateFrom = getUniversalDate(this.editEventInfo.dateFrom); 
        (<any>this.editEventInfo.dateFrom)= new Date(dateFrom.getFullYear(), dateFrom.getMonth(), dateFrom.getDate(), parseInt(this.timeFrom.substr(0,2)), parseInt(this.timeFrom.substr(3,2)))
        this.editEventInfo.dateFrom = getUniversalISOStringDate(<any>this.editEventInfo.dateFrom);
        
        var dateTo = getUniversalDate(this.editEventInfo.dateTo); 
        (<any>this.editEventInfo.dateTo) = new Date(dateTo.getFullYear(), dateTo.getMonth(), dateTo.getDate(), parseInt(this.timeTo.substr(0,2)), parseInt(this.timeTo.substr(3,2)))
        this.editEventInfo.dateTo = getUniversalISOStringDate(<any>this.editEventInfo.dateTo);

        EventClient.editInfoEvent(this.editEventInfo)
        .then(x => {
            this.$emit('close');
        })
    }

    searchCity() {
        var modalTitle = LocalizationServices.getLocalizedValue("app_indirizzoevento", "Indirizzo evento");
        var question = LocalizationServices.getLocalizedValue("app_dovesiterralevento", "Dove si terrà l\'evento?");
        
        this.$opModal.show(SelectCityModal, {
            addressTitle: modalTitle,
            addressQuestion: question,
            searchText: this.editEventInfo.address.completeAddress,
            callback: (city: OM.Address) => {
                this.editEventInfo.address = city;
                this.$opModal.closeLast();
            }
        })
    }

}

