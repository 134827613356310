
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { BaseCommunityClient, CategoryAttachmentClient, CommunityClient } from '@/services/Services';
import ConfirmModal from '@/components/confirmModal.vue';
import ModalSuccessError from '@/components/modalSuccessError.vue';
import * as VM from '@/viewModel';
import { LocalizationServices } from '@/services/LocalizationServices';

@Options({})
export default class AddAllegatiCategoryModal extends Vue {

    @Prop() callback: () => void;
    @Prop() communityIdentifier: string;
    local: VM.CategoryAttachmentVM = new VM.CategoryAttachmentVM;


    created() {
       
    }

    save() {
        console.log("aggiunta CATEGORIA")
        this.local.communityIdentifier = this.communityIdentifier;
        CategoryAttachmentClient.addCategoryAttachment(this.local)
        .then(x => {
            
            
        })
        .finally(() => {
            this.callback();
        })
    }

    get disabled() {
        return !this.local;
    }

}
