
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { CommunityClient } from '@/services/Services';
import CustomConfirm from '@/components/customConfirm.vue';
import store from '@/store';
import * as OM from '@/model';
import { LocalizationServices } from '@/services/LocalizationServices';

@Options({})
export default class VisibilityModal extends Vue {

    @Prop() communityIdentifier: string;
    @Prop() reload : () => null;

    type: OM.CommunityType = store.state.community.communityType;
    communityPublic = OM.CommunityType.Public;
    communityOnRequest = OM.CommunityType.OnRequest;
    communityClose = OM.CommunityType.Close;

    save(){
        if(this.type != store.state.community.communityType && this.type == OM.CommunityType.Public){
            var title = LocalizationServices.getLocalizedValue("app_attenzione", "Attenzione!");
            var text = LocalizationServices.getLocalizedValue("app_passandoinmodalitapubblicalerichiestevengonoaccettate", "Passando in modalità pubblica tutte le richieste verranno accettate. Continuare?");

            this.$opModal.show(CustomConfirm, {
                title: title,
                text: text,
                callback: (value: boolean) => {
                    if(!value)
                        this.$opModal.closeLast();
                    else {
                        this.$opModal.closeLast();
                        this.updateCommunityType();
                    }
                }
            })
        }
        else 
            this.updateCommunityType();
    }

    updateCommunityType(){
        CommunityClient.updateCommunityType({
            type: this.type,
            identifier: this.communityIdentifier
        })
        .then(x => {
            store.state.community.communityType = this.type;
            this.$emit('close')
            this.reload();
        })
    }

}

