
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { CommunityClient } from '@/services/Services';
import store from '@/store';
import UoseWriterTextarea from '@/components/uoseWriterTextarea.vue';

@Options({
    components:{
        UoseWriterTextarea
    }
})
export default class DescriptionModal extends Vue {

    @Prop() communityIdentifier: string;

    description: string = store.state.community.description;

    save(){
        CommunityClient.updateDescription({
            name: this.description,
            identifier: this.communityIdentifier
        })
        .then(x => {
            store.state.community.description = this.description;
            this.$emit('close');
        })
    }

}

