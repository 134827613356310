
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { CommunityClient } from '@/services/Services';
import PermissionsModal from '@/components/permissionsModal.vue';
import ConfirmModal from '@/components/confirmModal.vue';
import ModalSuccessError from '@/components/modalSuccessError.vue';
import WhoCanModal from './whoCanModal.vue';
import * as OM from '@/model';
import * as VM from '@/viewModel';
import store from '@/store';
import { LocalizationServices } from '@/services/LocalizationServices';

@Options({})
export default class SettingsModal extends Vue {

    @Prop() communityIdentifier: string;
    type: string = "";

    created(){
        CommunityClient.getById(this.communityIdentifier)
        .then(x => {
            this.type =  x.type;
        })
    }

    permissions(){
        CommunityClient.getPermissions(this.communityIdentifier)
        .then(x => {
            var type = this.type; 
            var key = `app_permessi${type}`;
            var defaultValue = `Permessi ${type}`;
            var title = LocalizationServices.getLocalizedValue(key, defaultValue);

            var infoLabel = LocalizationServices.getLocalizedValue("app_possonomodificareleinformazioni", "Possono modificare le informazioni");
            var invitationLabel = LocalizationServices.getLocalizedValue("app_possonoinvitareutenti", "Possono invitare utenti");
            var contentLabel = LocalizationServices.getLocalizedValue("app_possonoscrivereinchat", "Possono scrivere in chat");

            this.$opModal.show(PermissionsModal, {
                title: title,
                infoLabel: infoLabel,
                invitationLabel: invitationLabel,
                contentLabel: contentLabel,
                permissions: x || new OM.PermissionSettings(),
                saveCb: (model: OM.PermissionSettings) => {
                    store.state.globalSpinner++;
                    CommunityClient.setPermissions({
                        identifier: this.communityIdentifier,
                        value: model
                    })
                    .then(x => this.$opModal.closeLast())
                    .finally(() => store.state.globalSpinner--)
                }
            })
        })
    }

    administrators() {
        var type = this.type; 
        var key = `app_amministratori${type}`;
        var defaultValue = `Amministratori ${type}`;
        var title = LocalizationServices.getLocalizedValue(key, defaultValue);

        var role = LocalizationServices.getLocalizedValue("app_amministratore", "Amministratore");
        CommunityClient.getAdmins(this.communityIdentifier)
        .then(x => {
            this.$opModal.show(WhoCanModal, {
                communityIdentifier: this.communityIdentifier,
                title: title,
                role: role,
                model: x,
                saveCb: (selected: VM.NameIdentifierSelected[]) => {
                    store.state.globalSpinner++;
                    CommunityClient.setAdmins({
                        identifier: this.communityIdentifier,
                        values: selected.map(c => c.identifier)
                    })
                    .then(x => this.$opModal.closeLast())
                    .finally(() => store.state.globalSpinner--)
                }
            })
        })
    }
    
    moderators() {
        var type = this.type; 
        var key = `app_moderatori${type}`;
        var defaultValue = `Moderatori ${type}`;
        var title = LocalizationServices.getLocalizedValue(key, defaultValue);

        var role = LocalizationServices.getLocalizedValue("app_moderatore", "Moderatore");
        CommunityClient.getModerators(this.communityIdentifier)
        .then(x => {
            this.$opModal.show(WhoCanModal, {
                communityIdentifier: this.communityIdentifier,
                title: title,
                role: role,
                model: x,
                saveCb: (selected: VM.NameIdentifierSelected[]) => {
                    store.state.globalSpinner++;
                    CommunityClient.setModerators({
                        identifier: this.communityIdentifier,
                        values: selected.map(c => c.identifier)
                    })
                    .then(x => this.$opModal.closeLast())
                    .finally(() => store.state.globalSpinner--)
                }
            })
        })
    }
    
    removeCommunity(){

        var type = this.type; 
        var key = `app_vuoieliminarequesta${type}`;
        var defaultValue = `Vuoi eliminare questa ${type}`;
        var text = LocalizationServices.getLocalizedValue(key, defaultValue);

        this.$opModal.show(ConfirmModal, {
            text: text,
            okCb: () => {
                CommunityClient.disableById(this.communityIdentifier)
                .then( x => {
                    for(var i = 0; i < this.$opModal.modals().length; i++){
                        this.$opModal.closeLast();
                        this.$opModal.closeLast();
                    }
                    this.$router.replace("/communities/menu/Seguite");
                }).catch( err => {
                    this.$opModal.show(ModalSuccessError, {
                        message: err.message,
                    })
                })
            }
        })
    }

}

