
import { Options, Vue } from 'vue-class-component';
import store from '@/store';
import { BaseCommunityClient, CommunityClient, CommunityUserRequestClient, EventClient, FieraClient, PostClient } from '@/services/Services';
import Toggler from '@/components/toggler.vue';
// import AllPeopleModal from '@/components/allPeopleModal.vue';
import ImageGalleryModal from '@/components/imageGalleryModal.vue';
import PostGalleryModal from '@/components/postGalleryModal.vue';
import PostDetailModal from '@/components/postDetailModal.vue';
import SwiperSlides from '@/components/swiperSlides.vue';
import EditModal from './modals/editModal.vue';
import SettingsModal from './modals/settingsModal.vue';
import UsersBlocked from './modals/usersBlocked.vue';
import UsersRequest from './modals/usersRequest.vue';
import InviteUsers from './modals/inviteUsers.vue';
import * as OM from '@/model';
import * as VM from '@/viewModel';
import { Watch } from 'vue-property-decorator';

@Options({
    components: {
        SwiperSlides,
        Toggler
    }
})
export default class CommunityInfo extends Vue {

    allegati: OM.PostVm[] = [];
    requests: OM.RequestVM[] = [];
    isEvent: boolean = false;
    isFiera: boolean = false;

    communities: OM.NameIdentifier[] = [];
    slug: string = "";

    @Watch("$route.params.slug")
    async created() {
        this.isEvent = this.$route.path.indexOf("events") > -1;
        this.isFiera = this.$route.path.indexOf("fiera") > -1;

        if(!this.$route.params.slug)
            return;

        this.slug = this.$route.params.slug.toString();

        store.state.community = await BaseCommunityClient.getBySlug(this.slug)

        var proms = [];
        proms.push(PostClient.getByParentAllegati(this.slug));

        if(store.state.community.canSettings)
            proms.push(CommunityUserRequestClient.getAllRequest(store.state.community.identifier));

        Promise.all(proms)
        .then(x => {
            this.allegati = x[0];

            if(store.state.community.canSettings)
                this.requests = x[1]
            
            if(this.isFiera)
                (<any>store.state.community).communities.forEach(x => {
                    this.communities.push(...x.items);
                });
        })
    }

    getAttachmentBackImage(link: string) {
        // if(link.indexOf('icon_pdf') > -1)
        //     return '';
        return link;
    }

    openEditModal() {
        this.$opModal.show(EditModal, {
            communityIdentifier: store.state.community.identifier,
        }, () => {
            if(this.isEvent)
                EventClient.getBySlug(this.slug)
                .then( x => {
                    store.state.community = <any>x; 
                    store.state.community.dateFrom = store.state.community.dateFrom; 
                    store.state.community.dateTo = store.state.community.dateTo; 
                })
            else if(this.isFiera)
                FieraClient.getBySlug(this.slug)
                .then( x => {
                    store.state.community = <any>x;
                })
            else
                CommunityClient.getBySlug(this.slug)
                .then( x => {
                    store.state.community = x;
                })
                
            PostClient.getByParentAllegati(this.slug)
            .then(x => {
                this.allegati = x;
            })
        }) 
    };

    async openSettingsModal() {
        var community = await CommunityClient.getBySlug(this.$route.params.slug.toString());

        this.$opModal.show(SettingsModal, {
            communityIdentifier: community.identifier
        }, () => {
            CommunityClient.getBySlug(this.slug)
            .then(x => {
                store.state.community = x;
            });
            PostClient.getByParentAllegati(this.slug)
            .then(x => {
                this.allegati = x;
            })
        })
    }

    openUsersBlockedModal() {
        var communityId = "";
        CommunityClient.getBySlug(this.$route.params.slug.toString())
        .then(x => {
            communityId = x.identifier;
        })

        this.$opModal.show(UsersBlocked, {
            
            communityIdentifier: communityId,
            saveCb: (data: VM.NameIdentifierSelected[]) => {
                var vm: OM.SetListVmOfGuid = new OM.SetListVmOfGuid();
                vm.identifier = store.state.community.identifier.toString();
                vm.values = data.map( x => x.identifier);

                CommunityClient.unblockUser(vm) 
                .then( x => {
                    this.$opModal.closeLast();
                    window.toastr.success("Utente abilitato con successo!")
                }).catch( err => {
                    window.toastr.error(err.message)
                })
            }
        });
    }

    openInviteUser() {
        this.$opModal.show(InviteUsers, {
            communityIdentifier: store.state.community.identifier.toString()
        });
    }

    openUserRequest() {
        this.$opModal.show(UsersRequest, {
            requests: this.requests,
            saveCb: (data: OM.RequestVM) => {
                CommunityUserRequestClient.acceptRequest(data) 
                .then( x => {
                    var find = this.requests.indexOf(data);
                    this.requests.splice(find, 1);
                }).catch( err => {
                    window.toastr.error(err.message)
                })
            }, deleteCb: (data: OM.RequestVM) => {
                CommunityUserRequestClient.voidRequest(data) 
                .then( x => {
                    var find = this.requests.indexOf(data);
                    this.requests.splice(find, 1);
                }).catch( err => {
                    window.toastr.error(err.message)
                })
            }
        });
    }

    setSubscribtion(value) {
        if(value) {
            CommunityClient.subscribe(store.state.community.identifier);
        } else {
            CommunityClient.unSubscribe(store.state.community.identifier);
        }
    }

    openPerson(item: OM.NameIdentifier) {
        if(item.identifier == store.state.user.identifier)
            return;
        
        this.$router.push('/profile/' + item.identifier);
    }

    openPeopleModal(model: OM.NameIdentifier[]) {
        store.state.allPeoplePage.title = store.state.community.title;
        store.state.allPeoplePage.model = model;
        this.$router.push('/allPeople');
    }

    openMediaModal(index: number = null) {
        this.$opModal.show(ImageGalleryModal, {
            title: store.state.community.title,
            gallery: store.state.community.gallery,
            index: index
        })
    }

    openAllegatiModal(index: number = null) {
        if(!index && index != 0){
            this.$opModal.show(PostGalleryModal, {
                title: store.state.community.title,
                gallery: this.allegati,
            })
        } else {
            this.$opModal.show(PostDetailModal, {
                post: this.allegati[index],
                communityIdentifier: store.state.community.identifier
            })
        }
    }

    get updatedCommunity() {
        return store.state.community;
    }

    openCommunity(item: OM.NameIdentifier) {
        this.$router.push('/communities/' + item.identifier + '/Bacheca');
    }

}

