
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { makeFilePreview } from '@/utils';
import Post from '@/components/post.vue';
import ProgressBar from '@/components/progressBar.vue';
import UoseWriter from '@/components/uoseWriter.vue';
import store from '@/store'; 
import * as VM from '@/viewModel';
import * as OM from '@/model';
import * as CONST from '@/const';
import { CategoryAttachmentClient, PostClient } from '@/services/Services';

@Options({
    components: {
        Post,
        ProgressBar,
        UoseWriter
    }
})
export default class AllegatiCreateModal extends Vue {

    @Prop() communityIdentifier: string;
    @Prop() callback: () => void;

    vm: VM.CreatePostVm = new VM.CreatePostVm();
    isCordova: boolean = !!window.cordova;
    uploading: boolean = false;
    uploadProgress: number = 0;

    fileInput: any;
    todayDate: Date = new Date();
    ableCategories: boolean = false;

    categoriesAllegati: VM.CategoryAttachmentVM[] = [];
    tempCategory: VM.CategoryAttachmentVM = new VM.CategoryAttachmentVM;

    created() {
        this.vm.containerIdentifier = this.communityIdentifier;
        this.vm.postType = CONST.NotificationType.AllegatoNew;

        CategoryAttachmentClient.getCategoryAllAttachmentByCommunity(this.communityIdentifier)
        .then(x => {
            this.categoriesAllegati = x;
        })
    }

    mounted() {
        this.fileInput = this.$refs.fileInput;
    }



    preview: any = null;
    @Watch('vm.attachment')
    onAttachmentChange(next, prev){
        this.makePreview();
    }
    makePreview() {

        if(this.vm.attachment) {
            makeFilePreview(this.vm.attachment)
            .then(x => {
                this.preview = x;
            })
            // if(typeof this.vm.attachment == "string"){
            //     this.preview = this.vm.attachment;
            // } else {
            //     makeFilePreview(this.vm.attachment)
            //     .then(x => {
            //         this.preview = x;
            //     })
            // }
        }
    }

    removeAttachment() {
        this.preview = "";
        this.vm.attachment = null;
    }

    doPost() {
        delete this.vm.publishedDate;
        store.state.globalSpinner++;
        this.vm.isAllegato = true;
        //se l' utente non ha abilitato le categorie semplicemente lascio id a stringa vuota(che equivale a nesssuna categoria)
        if(this.ableCategories)
        this.vm.categoryAttachment = this.tempCategory;
        else
        this.vm.categoryAttachment.id = "";
        PostClient.createOrUpdatePost(this.vm)
        .then(x => {
            PostClient.updatePostImage(x, this.vm.attachment)
            .then(x => {
                this.$emit('close');
            })
            .finally(() => {
                store.state.globalSpinner--;
                this.uploading = false;
                this.callback();
            });
        })
    }

    covertObjectToBinary(obj) {
        var output = '';
        var input = JSON.stringify(obj); // convert the json to string.
        // loop over the string and convert each charater to binary string.
        for (var i = 0; i < input.length; i++) {
            output += input[i].charCodeAt(0).toString(2) + " ";
        }
        return output.trimEnd();
    }

}

