
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import Post from '@/components/post.vue';
import * as OM from '@/model';

@Options({
    components: {
        Post
    }
})
export default class PostDetailModal extends Vue {

    @Prop() post: OM.PostVm;
    @Prop() communityIdentifier: string;

    created() {}

}

