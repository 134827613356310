
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { BaseCommunityClient, CommunityClient } from '@/services/Services';
import FileInput from '@/components/fileInput.vue';
import RemovableImage from '@/components/removableImage.vue';
import store from '@/store'; 
import * as OM from '@/model';
import * as VM from '@/viewModel';

@Options({
    components: {
        FileInput,
        RemovableImage
    }
})
export default class GalleryModal extends Vue {

    @Prop() communityIdentifier: string;

    vm: VM.FileIdentifier = new VM.FileIdentifier();
    isCordova: boolean = !!window.cordova;

    fileInput: any;

    created() {
        this.vm.identifier = this.communityIdentifier;
    }

    mounted() {
        this.fileInput = this.$refs.fileInput;
    }

    @Watch('vm.file')
    onFileChange(next, prev) {
        if(this.vm.file) {
            store.state.globalSpinner++;
            BaseCommunityClient.addGalleryImage(this.vm.identifier, this.vm.file)
            .then(x => {
                store.state.community.gallery.push(x);
            })
            .finally(() => store.state.globalSpinner--)
        }
    }

    removeImage(index) {
        let nameIdentifier: OM.NameIdentifier = {
            name: store.state.community.gallery[index],
            identifier: this.communityIdentifier
        };
        store.state.globalSpinner++;
        CommunityClient.removeFromGallery(nameIdentifier)
        .then(x => {
            store.state.community.gallery.splice(index, 1);
        })
        .finally(() => store.state.globalSpinner--)
    }

    selectImage() {
        this.fileInput.$el.click();
    }

}

