
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import SwiperSlides from '@/components/swiperSlides.vue';
import PostDetailModal from '@/components/postDetailModal.vue';
import Post from '@/components/post.vue';
import store from '@/store';
import * as OM from '@/model';
import { CategoryAttachmentClient } from '@/services/Services';

@Options({
    components: {
        SwiperSlides,
        Post
    }
})
export default class PostGalleryModal extends Vue {

    @Prop() title: string;
    @Prop() gallery: OM.PostVm[];

    allCategories: OM.CategoryAttachmentVM[] = [];
    categorySelectedId: string = 'all';

    created(){
        CategoryAttachmentClient.getCategoryAllAttachmentByCommunity(store.state.community.identifier)
        .then(x => {
            this.allCategories = x;
        })
    }

    openPostDetail(item: OM.PostVm){
        this.$opModal.show(PostDetailModal, {
            post: item,
            communityIdentifier: store.state.community.identifier
        })
    }

    removed(index){
        this.gallery.splice(index, 1);
    }

    get filtredGallery(){
        if(this.categorySelectedId == 'all' ){
            var ris = this.gallery;
        }else if(this.categorySelectedId == 'noCat'){
            var ris = this.gallery.filter(x => x.category == "")
        }else{
            var ris = this.gallery.filter(x => x.category == this.categorySelectedId)
        }
        return ris
    }

    countCategory(cat : string){
        if(cat == 'all' ){
            var ris = this.gallery.length;

        }else if(cat == 'noCat'){
            var ris = this.gallery.filter(x => x.category == "").length
        }else{
            var ris = this.gallery.filter(x => x.category == cat).length
        }
        return ris
    }
}

