
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { UserClient } from '@/services/Services';
import store from '@/store';
import * as VM from '@/viewModel';

@Options({})
export default class WhoCanModal extends Vue {

    @Prop() communityIdentifier: string;
    @Prop() title: string;
    @Prop() role: string;
    @Prop() model: string[];
    @Prop() saveCb: (data: VM.NameIdentifierSelected[]) => void;

    contacts: VM.NameIdentifierSelected[] = [];
    localSelected: VM.NameIdentifierSelected[] = [];
    search: string = "";
    userIdentifier: string = "";

    created() {
        this.userIdentifier = store.state.user.identifier;

        UserClient.getContactsAndCommunityMembers(this.communityIdentifier)
        .then(x => {
            this.contacts = x.filter( x => x.identifier != store.state.community.owner.identifier);
            this.contacts.forEach(x => {
                if(x.identifier != this.userIdentifier && this.model.findIndex(c => x.identifier == c) > -1) {
                    x.selected = true;
                    this.localSelected.push(x);
                }
            })
        })
    }
    
    select(item: VM.NameIdentifierSelected) {
        let ind = this.localSelected.findIndex(x => x.identifier == item.identifier);
        if(ind > -1) {
            item.selected = false;
            this.localSelected.splice(ind, 1);
        } else {
            item.selected = true;
            this.localSelected.push(item);
        }
    }
    
    get filtered() {
        var users = this.contacts.filter( x => {
            return x.identifier != this.userIdentifier
        })
        if(!this.search.trim()) {
            return users;
        } else {
            return users.filter(x => x.name.toLowerCase().indexOf(this.search.toLowerCase().trim()) > -1);
        }
    }

    save() {
        if(this.model.find( x => x == this.userIdentifier) != null){
            var temp = {...this.contacts.find( x => x.identifier == this.userIdentifier)};
            temp.selected = true;
            this.localSelected.push(temp);
        }

        this.saveCb(this.localSelected);
    }

}

