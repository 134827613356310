
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { CommunityClient, UserClient } from '@/services/Services';
import * as OM from '@/model';

@Options({})
export default class InviteUsers extends Vue {

    @Prop({
        default: ""
    }) communityIdentifier: string;

    completeName: string = "";
    results: OM.SearchUserVM[] = [];

    invitedUsers: string[] = [];
    errorMessage: string = "";

    created() {}

    sendInvitation(identifier: string) {
        CommunityClient.inviteUser(this.communityIdentifier, identifier)
        .then(x => {
            this.invitedUsers.push(identifier);
        })
        .catch(err => {
            this.errorMessage = err.message;

            setTimeout(() => {
                this.errorMessage = "";
            }, 3000);
            // window.toastr.error(err);
        })
    }

    timeoutHandler;
    @Watch('completeName')
    changedText(){
        clearTimeout(this.timeoutHandler);
        if(this.completeName.length < 3)
            return;

        this.timeoutHandler = setTimeout(() => {
            UserClient.searchUser(this.completeName)
            .then( x => {
                this.results = x;
            })
        }, 1000);
    }

    isInvited(identifier: string) {
        return this.invitedUsers.indexOf(identifier) > -1;
    }

}

