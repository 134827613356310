
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { CommunityClient } from '@/services/Services';
import Removable from '@/components/removable.vue';
import * as VM from '@/viewModel';

@Options({
    components: {
        Removable
    }
})
export default class UsersBlocked extends Vue {

    @Prop() communityIdentifier: string;
    @Prop() saveCb: (data: VM.NameIdentifierSelected[]) => void;

    users: VM.NameIdentifierSelected[] = [];
    localSelected: VM.NameIdentifierSelected[] = [];
    search: string = "";

    created() {
        CommunityClient.getUsersBlocked(this.communityIdentifier)
        .then(x => {
            this.users = x;
        })
    }
    
    select(item: VM.NameIdentifierSelected) {
        let ind = this.localSelected.findIndex(x => x.identifier == item.identifier);
        if(ind > -1) {
            item.selected = false;
            this.localSelected.splice(ind, 1);
        } else {
            item.selected = true;
            this.localSelected.push(item);
        }
    }
    
    get filtered() {
        if(!this.search.trim()) {
            return this.users;
        } else {
            return this.users.filter(x => x.name.toLowerCase().indexOf(this.search.toLowerCase().trim()) > -1);
        }
    }

    save() {
        this.saveCb(this.localSelected);
    }

}

