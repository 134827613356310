
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import Removable from '@/components/removable.vue';
import * as OM from '@/model';

@Options({
    components: {
        Removable
    }
})
export default class UsersRequest extends Vue {

    @Prop({
        default: () => []
    }) requests: OM.RequestVM[];

    @Prop() saveCb: (data: OM.RequestVM) => void;
    @Prop() deleteCb: (data: OM.RequestVM) => void;

    search: string = "";

    created() {}
    
    get filtered() {
        return this.requests.filter( x => {
            return x.from.name.trim().toLowerCase().indexOf(this.search.toLowerCase().trim()) > -1
        })
    }
}
