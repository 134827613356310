
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import DescriptionModal from './descriptionModal.vue';
import BackgroundModal from './backgroundModal.vue';
import LogoModal from './logoModal.vue';
import GalleryModal from './galleryModal.vue';
import AllegatiModal from './allegatiModal.vue';
import VisibilityModal from './visibilityModal.vue';
import EditEventModal from './editEventModal.vue';

@Options({})
export default class CommunityEditModal extends Vue {

    @Prop() communityIdentifier: string;
    @Prop() reload : () => null;

    title : string = "Community";
    isEvent: boolean = false;

    created(){
        if(this.$route.path.indexOf("events") > -1){
            this.title = "Evento";
            this.isEvent = true;
        }
        else if(this.$route.path.indexOf("fiera") > -1)
            this.title = "Fiera";
    }

    description(){
        this.$opModal.show(DescriptionModal, {
            communityIdentifier: this.communityIdentifier
        })
    }

    visibility(){
        this.$opModal.show(VisibilityModal, {
            communityIdentifier: this.communityIdentifier,
            reload: () => {
                this.reload();
            }
        })
    }
    
    backgroundModal(){
        this.$opModal.show(BackgroundModal, {
            communityIdentifier: this.communityIdentifier
        })
    }
    
    logoModal(){
        this.$opModal.show(LogoModal, {
            communityIdentifier: this.communityIdentifier
        })
    }
    
    galleryModal(){
        this.$opModal.show(GalleryModal, {
            communityIdentifier: this.communityIdentifier
        })
    }
    
    allegatiModal(){
        this.$opModal.show(AllegatiModal, {
            communityIdentifier: this.communityIdentifier
        })
    }

    editEvent(){
        this.$opModal.show(EditEventModal, {
            eventIdentifier: this.communityIdentifier
        })
    }

}

