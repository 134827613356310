
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { CategoryAttachmentClient, PostClient } from '@/services/Services';
import AllegatiCreateModal from './allegatiCreateModal.vue';
import Post from '@/components/post.vue';
import * as OM from '@/model';
import AllegatiCategoriesModal from './allegatiCategoriesModal.vue';
import { CategoryAttachmentVM } from '@/viewModel';

@Options({
    components: {
        Post
    }
})
export default class AllegatiModal extends Vue {

    @Prop() communityIdentifier: string;

    posts: OM.PostVm[] = [];
    allCategories: OM.CategoryAttachmentVM[] = [];
    categorySelectedId: string = 'all';

    created() {
        this.init();
    }

    init() {
        Promise.all([
            PostClient.getByParentAllegati(this.$route.params.slug.toString()),
            CategoryAttachmentClient.getCategoryAllAttachmentByCommunity(this.communityIdentifier)
        ])
        .then(xs => {
            this.posts = xs[0];            
            this.allCategories = xs[1];
        })
    }

    removed(index) {
        this.posts.splice(index, 1);
    }

    postedited(post: OM.PostVm, index: number){
        this.posts.splice(index, 1, post);
    }

    newAttachment() {
        this.$opModal.show(AllegatiCreateModal, {
            communityIdentifier: this.communityIdentifier,
            callback: () => {
                this.init();
            }
        });
    }

    allegatiCategories(){
        this.$opModal.show(AllegatiCategoriesModal, {
            communityIdentifier: this.communityIdentifier,
            closeCallBack: () => {
                this.$opModal.closeLast();
                this.init();
            }
        })
    }

    get filtredPost(){
        if(this.categorySelectedId == 'all' ){
            var ris = this.posts;
        }else if(this.categorySelectedId == 'noCat'){
            var ris = this.posts.filter(x => x.category == "")
        }else{
            var ris = this.posts.filter(x => x.category == this.categorySelectedId)
        }
        return ris
    }

    countCategory(cat : string){
        if(cat == 'all' ){
            var ris = this.posts.length;

        }else if(cat == 'noCat'){
            var ris = this.posts.filter(x => x.category == "").length
        }else{
            var ris = this.posts.filter(x => x.category == cat).length
        }
        return ris
    }
}

